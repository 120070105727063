
export const openDB = async (dbname, upgrader) => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(dbname, 1);
      request.onerror = e => reject(e.target);
      request.onsuccess = e => resolve(e.target.result);
      request.onupgradeneeded = e => {
        upgrader(e);
      };
    });
  };
  
  
export const getIndexDBKey = async (db, store, key) => {
    return new Promise((resolve, reject) => {
      var transaction = db.transaction([store]);
      var objectStore = transaction.objectStore(store);
      var request = objectStore.get(key);
      request.onerror = function(event) {
        reject(event);
      };
      request.onsuccess = function(event) {
        resolve(request.result);
      };
    });
  
  }
