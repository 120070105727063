
// function gotStream(stream) {
//     window.AudioContext = window.AudioContext || window.webkitAudioContext;
//     var audioContext = new AudioContext();


//     // Create an AudioNode from the stream
//     var mediaStreamSource = audioContext.createMediaStreamSource(stream);

//     // Connect it to destination to hear yourself
//     // or any other node for processing!
//     mediaStreamSource.connect(audioContext.destination);
//     var video = document.querySelector('video');
//     var videoTracks = stream.getVideoTracks();
//     window.stream = stream; // make variable available to browser console
//     video.srcObject = stream;
// }

// function onfail(error) {
//     console.log("permission not granted or system don't have media devices."+error.name);
// }

// navigator.getUserMedia({audio:true,video:true}, gotStream,onfail);


const check = (permission) => navigator.permissions ? navigator.permissions.query(permission)
    .then(({ state }) => console.log(permission, state))
    .catch(err => console.log("unknown", permission)) : {};

check({ name: "camera" });
// check({ name: "bluetooth" });
// check({ name: "speaker" });
check({ name: "microphone" });
check({ name: "notifications" });
check({ name: "geolocation" });
check({ name: "background-fetch" });
check({ name: "background-sync" });
// check({ name: "ambient-light-sensor" });


//check({ name: "push" });
check('push', {userVisibleOnly: true});

check({ name: "persistent-storage" });
check({ name: "background-sync" });


check('accelerometer');
check('gyroscope');
check('magnetometer');

// https://developers.google.com/web/updates/2018/07/page-lifecycle-api

console.log(document.hidden)
console.log(document.hasFocus())

//Returns current visibility state: visible, hidden, prerender or unloaded.
console.log(document.visibilityState)
// document.addEventListener('visibilitychange')
console.log("discarded", document.wasDiscarded)

// document.addEventListener('freeze')
// document.addEventListener('resume')
function getState() {
    if (document.visibilityState === 'hidden') {
      return 'hidden';
    }
    if (document.hasFocus()) {
      return 'focused';
    }
    return 'not focused';
  };


var state = getState();

function logStateChange(nextState) {
  var prevState = state;
  if (nextState !== prevState) {
    var timeBadge = new Date().toTimeString().split(' ')[0];
    var newLog = document.createElement('p');
    newLog.innerHTML = '' + timeBadge + ' State changed from ' + prevState + ' to ' + nextState + '.';
    // target.appendChild(newLog);
    console.log(newLog)
    state = nextState;
  }
};



function onPageStateChange() {
    logStateChange(getState())
  console.log(state);
}
  

['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(function (type) {
    window.addEventListener(type, onPageStateChange, {capture: true});
  });


// window.addEventListener('freeze', onFreeze, {capture: true});
// ange('terminated');
//   }
// }



function onPageHide(event) {
    console.log(event.persisted)
    // if (event.persisted) {
    //   // If the event's persisted property is `true` the page is about
    //   // to enter the page navigation cache, which is also in the frozen state.
    //   logStateChange('frozen');
    // } else {
    //   // If the event's persisted property is not `true` the page is about to be unloaded.
    //   logStateChange('terminated');
    // }
}

window.addEventListener('pagehide', onPageHide, {capture: true});



//navigator.storage.estimate().then(console.log)
// navigator.storage.persist()

// screen​.orientation


// navigator.onLine
// window.addEventListener('online', listener)
// window.addEventListener('offline', listener)


// navigator​.deviceMemory
// navigator.getBattery()
// navigator​.vibrate


// MediaRecorder.isMimeTypeSupported(mimeType)


async function queryMediaDevices()  {
  const e = await navigator.mediaDevices.enumerateDevices();
  e.forEach(x => console.log(x.toJSON()))
  // console.log(e);
  console.log(navigator.mediaDevices.getSupportedConstraints())
}


navigator.mediaDevices.addEventListener('devicechange', ev => {
  queryMediaDevices();
})

//queryMediaDevices();

if (false) {
// Prefer camera resolution nearest to 1280x720.
var constraints = { audio: true, video: false } // { width: 1280, height: 720 } }; 
// var constraints = { audio: { deviceId: { exact: 'ed570b6b4d388d6c4684c53b705741ef3b1e76beb60b0df7f42878c7d3f0d0fe' } }, video: false }; 
navigator.mediaDevices.getUserMedia(constraints)
.then(function(mediaStream) { queryMediaDevices() })
.catch(function(err) { console.log(err.name + ": " + err.message); }); //


}