import React, { } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { openDB, getIndexDBKey } from './db'
// import ringSound from './ring.wav';
// import useSound from 'use-sound';


import Main from './Main';
import './rtfsdk/media'

// import * as serviceWorker from './serviceWorker';

// String (UCS-2) to Uint8Array
//
// because... JavaScript, Strings, and Buffers
function strToUint8(str) {
  return new TextEncoder().encode(str);
}



// Binary String to URL-Safe Base64
//
// btoa (Binary-to-Ascii) means "binary string" to base64
function binToUrlBase64(bin) {
  return btoa(bin)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+/g, '');
}

// UTF-8 to Binary String
//
// Because JavaScript has a strange relationship with strings
// https://coolaj86.com/articles/base64-unicode-utf-8-javascript-and-you/
function utf8ToBinaryString(str) {
  var escstr = encodeURIComponent(str);
  // replaces any uri escape sequence, such as %0A,
  // with binary escape, such as 0x0A
  var binstr = escstr.replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
  });

  return binstr;
}

// UCS-2 String to URL-Safe Base64
//
// btoa doesn't work on UTF-8 strings
function strToUrlBase64(str) {
  return binToUrlBase64(utf8ToBinaryString(str));
}


// Uint8Array to URL Safe Base64
//
// the shortest distant between two encodings... binary string
function uint8ToUrlBase64(uint8) {
  var bin = '';
  uint8.forEach(function(code) {
      bin += String.fromCharCode(code);
  });
  return binToUrlBase64(bin);
}

// var JWK = {

//   thumbprint: async (jwk) => { 

//     // lexigraphically sorted, no spaces
//     var sortedPub = '{"crv":"CRV","kty":"EC","x":"X","y":"Y"}'
//       .replace('CRV', jwk.crv)
//       .replace('X', jwk.x)
//       .replace('Y', jwk.y);

//     // The hash should match the size of the key,  but we're only dealing with P-256
//     return uint8ToUrlBase64(new Uint8Array(await window.crypto.subtle.digest({ name: 'SHA-256' }, strToUint8(sortedPub))))
//   },

// }

const selectSigning = (alg) => {
  switch (alg.name) {
    case 'RSA-PSS':      
      return { alg: 'PS256', sigType: { name: 'RSA-PSS', saltLength: 32 } };
    case 'ECDSA':
      return { alg: 'ES384', sigType: { name: 'ECDSA', hash: { name: 'SHA-384' } } };
    default:
      throw new Error("unsupported");
    }
}


const JWT = {

  sign: async function({ privateKey, publicKey }, headers, payload) {

    // const alg = 'ES384';
    // const sigType = { name: 'ECDSA', hash: { name: 'SHA-384' } };
    // const alg = 'PS256';
    // const sigType = { name: 'RSA-PSS', saltLength: 32 };


    const { alg, sigType } = selectSigning(publicKey.algorithm);

    // alg must match the key.  type: 'JWT' is not required always ...
    headers = { alg, ...headers }

    // if there is no kid, then add the public key as the jwk.
    if (!headers.kid) {      
        headers.jwk = await crypto.subtle.exportKey("jwk", publicKey); 
    }


    //
    const jws = {
        protected: strToUrlBase64(JSON.stringify(headers)),
        payload: strToUrlBase64(payload)
    };

    // data to protect
    const data = strToUint8(`${jws.protected}.${jws.payload}`);

    // The signature and hash should match the bit-entropy of the key
    // https://tools.ietf.org/html/rfc7518#section-3
    const signature = await window.crypto.subtle.sign(sigType, privateKey, data)

    // contains JOSE signature, not x509. convert to Uint8.
    jws.signature = uint8ToUrlBase64(new Uint8Array(signature));

    // return created JWT.
    return jws;


  }

};

async function loadConfig({origin}) {

  const db = await openDB(`rtfs:${origin}`, (e) => {
    const db = e.target.result;
    console.log("Initializing local DB for origin", origin, db);
    var configStore = db.createObjectStore("config", { keyPath: "key" });
    configStore.createIndex("key", "key", { unique: true });  
  });

  //
  const val = await getIndexDBKey(db, "config", "keyPair");

  if (val) {
    return { keyPair: val.value };
  }

  const subtle = window.crypto.subtle;

  // todo:tpz: move to remote fetch config so we can upgrade over time.

  const isFirefox = false;

  const keyConfig =
  !isFirefox
  ? { name: "ECDSA", namedCurve: "P-384" }
  // firefox does not support ECDHA operations being stored, so we need to use RSA.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1133698 and https://bugzilla.mozilla.org/show_bug.cgi?id=1434898
  : { name: "RSA-PSS", modulusLength: 2048, publicExponent: new Uint8Array([1, 0, 1]), hash: "SHA-256", };

  let keyPair = await subtle.generateKey(keyConfig, false, ["sign", "verify"] );
  var configStore = db.transaction("config", "readwrite").objectStore("config");
  configStore.add({ key: 'keyPair', value: keyPair });
  return { keyPair };

}

  // // build the claims.
  // const claims = {};
  // claims.iss = 'https://my.device/';
  // claims.iat = new Date();
  // claims.exp = 1562392724;
  // //claims.nbf = ;
  // claims.jti = 'YNiXjHAmoehN8TsIgkvr1g'; // random token id
  // claims.sub = 'bJaHgdhob7ioPZ3ODdcp2w'; // user id (map to appid / azp)?
  // claims.aud = '';
  // return JSON.stringify(claims);

async function prepareShell(cb) {

  // high level origin root for context/partitioning.
  const origin = 'https://nlb.fs-sandbox-user-theo.aws.ucaas.dev';

  // fetch keypair.
  const { keyPair } = await loadConfig({origin});

  // exchange for an instance id + kid which we can use for authorizing.
  const payload = JSON.stringify({
    startedAt: new Date(),
    deviceName: 'Browser',
    browser: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
    },
    origin: document.origin,
  });

  // url request is going to.
  const url =  `${origin}/iid/`;

  const nonce = new Uint8Array(8);
  window.crypto.getRandomValues(nonce);

  // sign the header + payload.
  const jws = await JWT.sign(keyPair, { nonce: uint8ToUrlBase64(nonce), at: new Date(), url }, payload);

  const res = await fetch(url, { method: 'POST', headers: { 'authorization': `${jws.protected}..${jws.signature}`, 'content-type': 'application/json' }, body: payload });

  if (!res.ok) {
    // hmmph.
    console.log("request error", res);
  }

  const reply = await res.json();

  if (reply.status !== 'valid') {
    console.log(reply);
    console.log(`Location: ${res.headers.get('location')}`);
    console.log(`Link: ${res.headers.get('link')}`);  
  }

  cb();

}

/*
async function initWorker(cb) {


  try {

    // get background web worker going.
    const reg = await navigator.serviceWorker.register('/serviceworker.js', { scope: '/' });

    // ---

    if (reg.installing)
      console.log("INSTALLING:", reg.installing); // the installing worker, or undefined
    if (reg.waiting)
      console.log("WAITING:", reg.waiting); // the waiting worker, or undefined
    if (reg.active)
      console.log("ACTIVE:", reg.active); // the active worker, or undefined

    const activeWorker = reg.active;
    if (activeWorker) {
      console.log("active worker state", activeWorker.state);
      activeWorker.addEventListener('statechange', () => console.log("activeWorker.stateChange:", activeWorker.state));
    }
    
    reg.addEventListener('updatefound', () => {

      // A wild service worker has appeared in reg.installing!
      const newWorker = reg.installing;
      console.log("new worker: ", newWorker.state);

      // "installing" - the install event has fired, but not yet complete
      // "installed"  - install complete
      // "activating" - the activate event has fired, but not yet complete
      // "activated"  - fully active
      // "redundant"  - discarded. Either failed install, or it's been
      //                replaced by a newer version

      newWorker.addEventListener(
        'statechange', 
        () => console.log("newWorker.stateChange:", newWorker.state));

    });

  }
  catch (e) {
    console.log("failed to register service worjker", e);
    setTimeout(() => prepareShell(render), 5000);
    return;
  }

  
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    // This fires when the service worker controlling this page
    // changes, eg a new worker has skipped waiting and become
    // the new active worker.
    console.log("controller:", navigator.serviceWorker.controller);
  });


  if (navigator.serviceWorker.controller) {
    console.log('controller:', navigator.serviceWorker.controller);
  }


    
  navigator.serviceWorker.addEventListener('error', () => {
    console.log("error");
  });

    
  navigator.serviceWorker.addEventListener('message', () => {
    console.log("message");
  });



}
*/

// 
// 

/*
const login = (e) => {
  window.location = `https://nlb.fs-sandbox-user-theo.aws.ucaas.dev/integrations/start?redirect_uri=${window.location}`;
};

const Volume = () => {
  // const [ringing, toggleRing] = useState(false);
  // const [volume, setVolume] = React.useState(0.75);
  // const [play, { stop }] = useSound(ringSound, { volume, autoplay: true, loop: false });
  return <button onClick={login}>call</button>
  // return <button onClick={play}>call</button>
};
*/

const render = () => 
  ReactDOM.render(
    <React.StrictMode>
      {/* <App /> */}
      {/* <Volume/> */}
      <Main/>
    </React.StrictMode>,
    document.getElementById('root')
  );

prepareShell(render);


// Notification.requestPermission().then(function (permission) {
//   // If the user accepts, let's create a notification
//   if (permission === "granted") {
//     // var notification = new Notification();
//   }
// });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

/*
navigator.serviceWorker.register('serviceworker.js', {scope: '/'})
.then(function(reg) {

  console.log('Registration succeeded. Scope is ' + reg.scope);

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: 
      "BP0s8wC7MM02FTtm4CJ7vNPwOt7_ywk8IvC3aVTvCryS_ZNY5-0NQ3i-l4PCDmEOBPOFLfOjAZkKbaLCn7L4Pkw"
  };

  reg.showNotification(
    "Incoming Call",
    {
      body: 'Yay it works.',
      badge: 'images/badge.png',
      actions: [
        { title: 'xxx', action: 'a' },
        { title: 'xxx', action: 'b' },
      ],
      data: 'xxx',
      icon: 'images/icon.png',
      image: '',
      lang: 'en-US',
      requireInteraction: true,
      silent: false,
      tag: 'xxx',
      // timestamp: '',
    });


  return reg.pushManager.subscribe(subscribeOptions);

})
.then(function(pushSubscription) {

  console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
  return pushSubscription;

});
*/



// // Use serviceWorker.ready to ensure that you can subscribe for push
// navigator.serviceWorker.ready.then(
//   function(serviceWorkerRegistration) {
//     var options = {
//       // userVisibleOnly: true,
//       applicationServerKey: 
//     };
//     serviceWorkerRegistration.pushManager.subscribe(options).then(
//       function(pushSubscription) {
//         console.log(pushSubscription.endpoint);
//         // The push subscription details needed by the application
//         // server are now available, and can be sent to it using,
//         // for example, an XMLHttpRequest.
//       }, function(error) {
//         // During development it often helps to log errors to the
//         // console. In a production environment it might make sense to
//         // also report information about errors back to the
//         // application server.
//         console.log(error);
//       }
//     );
//   });