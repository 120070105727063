import React, { } from 'react';

import { SessionContext } from './rtfsdk/react/session';

// 

function ManagedClient() {

    return (<div>xxx</div>)

}


//

export default function Main() {
    return (
        <SessionContext.Provider value={{ state: 'initial' }}> 
            <div>
                [session <SessionContext.Consumer>{value => JSON.stringify(value)}</SessionContext.Consumer>]
            </div>
            <ManagedClient id="test"/>
            <div style={{ border: '1px solid red', fontSize: '12px', width: '500px', padding: 50 }}>
                <h1>Welcome to Your Cass</h1>
                <div>
                    <div>
                        <b>Location Updates</b>
                        <p>Stay in touch with the people you care about by receiving notifications about their location.</p>
                    </div>
                    <div>
                        <b>Title With Info</b>
                        <p>Some more text.</p>
                    </div>
                    <div>
                        <b>Inform &amp; Consent</b>
                        <p>Some more stuff.</p>
                    </div>
                </div>
                <div>
                    <div style={{ border: '1px solid red', backgroundColor: 'red', width: 200, padding: 10, textAlign: 'center', fontWeight: 'bold', color: 'white'  }}>
                        Continue
                    </div>
                </div>
            </div>
        </SessionContext.Provider>
    );
}

